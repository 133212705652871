// react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//component
import PropertyList from "./PropertyList"
import SearchBar from "../searchBar"

import { style } from "../../style/style";
import { Box } from "@mui/material";

export default function Properties(props) {
const navigate = useNavigate()

    return (
        <Box id="properties-box" sx={style.container}>
            <SearchBar
                setUniAllIndex={props.setUniAllIndex}
                setProperties={props.setProperties}
            />
            <PropertyList 
                uniIndex={props.uniIndex}
                properties={props.properties}
                setUniAllIndex={props.setUniAllIndex}
                setProperties={props.setProperties}
                APIData={props.APIData} />
       </Box>
    )
}
