export const filterData = {

    bedroomNumber: [
        {
            value: 0,
            label: 'Shared House',
        },
        {
            value: 1,
            label: '1 Bedroom',
        },
        {
            value: 2,
            label: '2 Bedrooms',
        },
        {
            value: 3,
            label: '3 Bedrooms',
        },
        {
            value: 4,
            label: '4 Bedrooms',
        },
        {
            value: 5,
            label: '5 Bedrooms',
        },
        {
            value: 6,
            label: '6 Bedrooms',
        },
        {
            value: 7,
            label: '7 Bedrooms',
        },
        {
            value: 8,
            label: '8 Bedrooms',
        },
        {
            value: 9,
            label: '9 Bedrooms',
        },
        {
            value: 10,
            label: '10 Bedrooms',
        },
    ]
}