import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {useScrollTo} from "../useScrollTo"

//Mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//carousel
import PropertySwipeImages from "./PropertySwipeImages";

import SearchBar from "../searchBar";

//icons
import BedIcon from './../../assets/bed-icon.png'
import LighteningBoltIcon from "./../../assets/lightening-bolt-icon.png";
import Camcorder from './../../assets/camcorder.png'
import Houses from './../../assets/houses.png'

import CalendarIcon from './../../assets/calendar-icon.png'
import LetterIcon from './../../assets/letter-icon.png'

import { style } from "../../style/style"

//placeholder image
import placeholderImage from "../../assets/placeholdermap.png";
import PropertyMap from "./PropertyMap";

//googlemap

export default function Property(props) {
  const { state } = useLocation();
  const navigate = useNavigate()
  useScrollTo(0,0)
  return (
    <>
      <SearchBar
        setUniAllIndex={props.setUniAllIndex}
        setProperties={props.setProperties}
      />
      <Grid container sx={style.propertyPage} spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8} sx={style.propertyPageTop}>

          <Box sx={style.propertyBackBox}>
            <Button
              onClick={() => navigate("/", { state: { property: state.property } })}
              sx={style.propertyButton}
              style={{
                width: "200px", fontWeight: "bold", marginBottom: "20px"
              }}
            >&lt; Back</Button>
          </Box>

          <PropertySwipeImages Images={state.property} />

          <Box>
            <Box sx={style.propertySummaryBox}>
              <Typography sx={style.propertyTitle}>{state.property.name}</Typography>
              <Box sx={style.propertyIconContainer}>
                <Box sx={style.propertyIconWrapper} >
                  <Typography sx={style.textBoldIcon}>
                    {state.property.distanceToUniMiles}
                  </Typography>
                  <Typography sx={style.propertyStatLabel}>
                    miles to Uni
                  </Typography>
                </Box>
                <Box sx={style.propertyIconWrapper} style={{flexDirection:'row'}}>
                  <Box
                    sx={style.propertyIcon}
                    component="img"
                    alt={"icon"}
                    src={BedIcon}
                  />
                   <Box>
                  <Typography sx={style.textBoldIcon}
                   style={{ textAlign: 'center'}}>
                    {state.property.bedroomCount} 
                  </Typography>
                  <Typography sx={style.propertyStatLabel}>
                    Bedrooms
                  </Typography>
                  </Box>
                </Box>

              </Box>

              <Typography sx={style.bodyTextLabel}>
                Description
              </Typography>
              <Typography sx={style.bodyText}>{state.property.fullDescription}</Typography>

              <Box sx={style.propertyIconContainer}>
                {state.property.videoURL && <Button 
                  onClick={() => { window.open(state.property.videoURL, '_blank') }}
                  sx={style.propertyIconBox} >
                  <Box
                    sx={style.propertyIcon}
                    component="img"
                    alt={"icon"}
                    src={Camcorder}
                  />
                  <Typography sx={style.propertyLinkLabel}>
                    Virtual Tour
                  </Typography>
                </Button>}
                {state.property.floorplanURL && <Button 
                  onClick={() => { window.open(state.property.floorplanURL, '_blank') }}
                  sx={style.propertyIconBox} >
                  <Box
                    sx={style.propertyIcon}
                    component="img"
                    alt={"icon"}
                    src={Houses}
                  />
                  <Typography sx={style.propertyLinkLabel}>
                    View Floorplan
                  </Typography>
                </Button>}
                {state.property.epcURL && <Button 
                  onClick={() => { window.open(state.property.epcURL, '_blank') }}
                  sx={style.propertyIconBox} >
                  <Box
                    sx={style.propertyIcon}
                    component="img"
                    alt={"icon"}
                    src={LighteningBoltIcon}
                  />
                  <Typography sx={style.propertyLinkLabel}>
                    EPC
                  </Typography>
                </Button>}
              </Box>
            </Box>
          </Box>

        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          {state.property.agentContactNumber && <Box>
            <Button
              onClick={() => { window.open("tel:" + state.property.agentContactNumber, "_blank")}}
              sx={style.propertyButton}
              style={{
                width: "100%", fontWeight: "bold",
                marginBottom: "20px"
              }}
            >
              Enquire: {state.property.agentContactNumber}</Button>
          </Box>}
          <Box>
            <Button
              onClick={() => { window.open("mailto:info@swi-pad.co.uk?subject=" + state.property.name) }}
              sx={style.propertyButton}
              style={{
                width: "100%", fontWeight: "bold",
                marginBottom: "20px"
              }}
            >
              Send Email</Button>
          </Box>
          <PropertyMap coordinates={state.property} />

        </Grid>
      </Grid>
    </>
  )
}