// react
import React, { useState, useEffect } from "react";

//webservice
import { WebServiceClient } from '../../services/WebServiceClient'
//data
import { filterData } from "../.././resources/filterSelectData"

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import { style } from "../../style/style";
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from "@mui/material/Typography";


import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
//icon
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';

//autocomplete and autosuggest
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';
import { margin } from "@mui/system";

import { styled } from "@mui/material/styles";

const uniDistances = [
    {
        value: 5,
        label: "3 miles +",
    },
    {
        value: 3,
        label: "0-3 miles",
    },
    {
        value: 0,
        label: "Under a mile",

    }
]



export default function Filter(props) {
    const [uniDistance, setUniDistance] = React.useState(
        uniDistances[0].value
    )
    const [uniDistanceLabel, setUniDistanceLabel] = React.useState()
    const [rentValueMin, setRentValueMin] = React.useState();
    const [rentValueMax, setRentValueMax] = React.useState();
    const [bedroomValueMin, setBedroomValueMin] = React.useState();
    const [bedroomValueMax, setBedroomValueMax] = React.useState();
    const [bedroomValueLabel, setBedroomValueLabel] = React.useState()
    const [orgID, setOrgID] = React.useState('')
    const [orgList, setOrgList] = React.useState()
    const [propertyDistanceMax, setPropertyDistanceMax] = React.useState();
    const [propertyDistanceMin] = React.useState();
    const [rentalYear, setRentalYear] = React.useState(2022)



    //API call after filter submit
    const handleFilter = () => {

        var url = 'listing/search?uniIndex=' + props.uniIndex + `&limit=70&skip=1`
        if (propertyDistanceMin) { url += '&minDist=' + propertyDistanceMin }
        if (propertyDistanceMax) { url += '&maxDist=' + propertyDistanceMax }
        if (bedroomValueMax) { url += '&minRooms=' + bedroomValueMax }
        if (bedroomValueMax) { url += '&maxRooms=' + bedroomValueMax }
        if (rentValueMin) { url += '&minPrice=' + rentValueMin }
        if (rentValueMax) { url += '&maxPrice=' + rentValueMax }

        WebServiceClient.GetAll(url).then(function (response) {
                console.log(response)
                if (response.data && response.data.data) {
                    props.setProperties(response.data.data)
                    console.log('apidata in filter', response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    //get values for year
    const currentYear = new Date().getFullYear()
    const nextYear = new Date().getFullYear() + 1

    const uniRentalYear = [
        {
            value: currentYear,
            label: "Now",
        },
        {
            value: nextYear,
            label: "Next year",
        },
    ]



    //onChange each filter
    const getUniDistance = (event) => {
        setPropertyDistanceMax(event.target.value)
        setUniDistanceLabel(event.target.value)
    }

    const getRentalYear = (event) => {
        setRentalYear(event.target.value)
    }

    const getOrgId = (e, option) => {
        setOrgID(option.id)
    }

    // fetch orglist
    useEffect(() => {
        const fetchOrgList = () => {
            WebServiceClient.FetchAll('swipad/orgs?orgId=118&siteId=null&apiToken=a7ee2d63-f2f4-45da-a347-c8ab989f1a99&limit=1&skip=0')
                .then(function (response) {
                    if (response.data && response.data.data) {
                        setOrgList(response.data.data)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchOrgList()
    }, [])
    const handleChangeRentValueMin = (event) => {
        setRentValueMin(event.target.value);
    }
    const handleChangeRentValueMax = (event) => {
        setRentValueMax(event.target.value);
    }

    // const handleChangeBedroomValueMin = (event, newValue, activeThumb) => {
    //     setBedroomValueMin(newValue)
    // }

    const handleChangeBedroomValueMax = (event) => {
        setBedroomValueMax(event.target.value)
        setBedroomValueLabel(event.target.value)
        // (event, newValue, activeThumb) => {
        //     setBedroomValueMax(newValue)
    }

    return (
        <Grid container direction="row" spacing={2} sx={style.filterStack}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl  sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}> Distance to uni</FormLabel>
                    <Select
                        sx={style.filterSelect}
                        labelId="distance-select-small"
                        id="distance-select-small"
                        value={uniDistanceLabel}
                        onChange={getUniDistance}>
                        {uniDistances.map((distance) => (
                            <MenuItem key={distance.value}
                                value={distance.value}
                            >
                                {distance.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item
           xs={12} sm={12} md={2} lg={3} xl={3} >
                <FormLabel sx={style.filterLabel}>Price PPP £</FormLabel>
                <Box sx={style.filterPriceContainer}>
                    <Grid item
                   xs={12} sm={12} md={6} lg={6} xl={6} >
                        <TextField label="min" type="number" value={rentValueMin} onChange={handleChangeRentValueMin} sx={style.filterPrice} />
                    </Grid>
                    <Grid item
                   xs={12} sm={12} md={6} lg={6} xl={6} >
                        <TextField label="max" type="number" value={rentValueMax} onChange={handleChangeRentValueMax} sx={style.filterPrice} />
                    </Grid>
                </Box>
            </Grid>

            <Grid item
           xs={12} sm={12} md={3} lg={3}>
                <FormControl  sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}># Bedrooms</FormLabel>
                    <Select
                        sx={style.filterSelect}
                        labelId="bedroom-select-small" s
                        id="bedroom-select-small"
                        value={bedroomValueLabel}
                        onChange={handleChangeBedroomValueMax}>
                        {filterData.bedroomNumber.map((bedroom) => (
                            <MenuItem key={bedroom.value}
                                value={bedroom.value}
                            >
                                {bedroom.label}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>


            </Grid>

            <Grid item
           xs={12} sm={8} md={2} lg={2}>
                <FormControl sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}>When</FormLabel>
                    <Select
                        sx={style.filterSelect}
                        labelId="when-select-small"
                        id="when-select-small"
                        value={rentalYear}
                        input={<OutlinedInput />}
                        onChange={getRentalYear}>
                        {uniRentalYear.map((year) => (


                            <MenuItem key={year.value}
                                value={year.value}
                            >
                                {year.label}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>
            </Grid>

            {/* <Grid item
           xs={12} sm={8} md={3} lg={3}>
                <FormControl  sx={style.filterControl}>
                    <FormLabel sx={style.filterLabel}>Filter by Company</FormLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={orgList}
                        sx={{  margin: "20px 0" }}
                        renderInput={(orgList) => <TextField {...orgList} label="Property Company" />}
                        getOptionLabel={(option) => option.name}
                        onChange={getOrgId}
                    />
                </FormControl>
                <div id="cats"></div>
                <div id="log"></div>
            </Grid> */}

            <Button
                id="btnSearchProperties"
                sx={style.propertyButton} style={{
                    width: "300px",
                    marginBottom: "30px",
                    margin: "auto",
                    display: "flex",
                }}
                onClick={() => handleFilter()}
            >
                Find Properties
            </Button>



        </Grid>
    );
}
