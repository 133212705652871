import { Helmet } from 'react-helmet';

/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom"
import './App.css';
// services
import { WebServiceClient } from '../src/services/WebServiceClient';

// components
import Properties from './components/properties/Properties';
// mui components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// style
import { theme } from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { style } from "./style/style";
import Property from './components/property/Property';


function App() {
  const [APIData, setAPIData] = useState([]);
  const [properties, setProperties] = useState([]);
  const [uniIndex, setUniAllIndex] = useState([])


  useEffect(() => {
    const fetchData = () => {
      WebServiceClient.FetchAll('swipad/unis')
        .then(function (response) {
          console.log(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData()
  }, [])




  return (
    <ThemeProvider theme={theme}>
      <div id='scroll-wrapper'>
        <Helmet>
          <script src="./resources/postMessageChild.js" ></script>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/> 
        </Helmet>
        <Grid container spacing={2} className="App" style={style.app}>
          <Box  id='propertiesSearch' sx={style.container}>
            <Routes>
              <Route
                path="/property"
                element={<Property />}
                setUniAllIndex={setUniAllIndex}
                setProperties={setProperties}
              />
              <Route
                path="/"
                element={<Properties
                  APIData={APIData}
                  properties={properties}
                  setUniAllIndex={setUniAllIndex}
                  setProperties={setProperties}
                  uniIndex={uniIndex}
                />
                }
              />
            </Routes>

            <div id="log"></div>
            <div id="cats"></div>
          </Box>
        </Grid >
      </div>
    </ThemeProvider>

  );
}

export default App;
