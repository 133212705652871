import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
  backgroundColor: "#EBF6F5",
    spacing: [100, 300, 20],
    typography: {
        fontFamily: 'Montserrat',
      fontWeightLight: 300,
      //fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h2: {
        color: "#000000",
        textAlign: "center",
      },
      h5: {
        textAlign: "center",
        paddingTop: "50px",
        fontSize: "30px",
        fontWeight: "700",
      },
      h6: {
        textAlign: "left",
        paddingLeft: "10px",
        marginTop: "-5px",
        fontSize: "15px",
        color:  "#000000",
      },
      title: {
        textAlign: "left",
        paddingLeft: "10px",
        fontWeight: "1000",
        fontSize: "30px",
        color:  "#000000",
        letterSpacing: "-1px",
      },
      body1: {
        textAlign: "center",
        // paddingTop: "50px",
        // paddingBottom: "10px",
        fontSize: "20px",
        color:  "#000000",
      },
      body2: {
        fontSize: "15px",
        //color: color.brandOlive,
        letterSpacing: "-1px",
        fontWeight: "1000",
      },
      subtitle: {
        textAlign: "left",
        paddingLeft: "10px",
        paddingBottom: "5px",
        fontSize: "15px",
        color:  "#000000",
        letterSpacing: "-1px",
        fontWeight: "1000",
        marginTop: "-10px",
      },
      caption: {
        textAlign: "center",
        fontSize: "18px",
        color:  "#000000",
      },
      caption2: {
        textAlign: "center",
        paddingTop: "30px",
        fontSize: "16px",
      },
      tabs: {
        fontSize: "20px",
        color:  "#000000",
      },
    },
});