// react
import React, { useState, useEffect } from "react";

//webservice
import { WebServiceClient } from '../services/WebServiceClient'


// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { flexbox } from '@mui/system';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
//autocomplete and autosuggest
import Autocomplete from '@mui/material/Autocomplete';


import { style } from "../style/style";
import { styled } from "@mui/material/styles";

//icon
import SearchIcon from '@mui/icons-material/Search';

export const CssInputAdornment = styled(InputAdornment)({
  "& .MuiInputAdornment-root": {
    paddingTop: "10px"
  }
});

export const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    borderRadius: "10px",
    backgroundColor: "white",
    "& input": {
      color: "black",
      background: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
        fontWeight: "bold"
      },
    },
    "& fieldset": {
      borderColor: "#34B298",
    },
    "&:hover fieldset": {
      borderColor: "#34B298",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#34B298",
    },
  },
});


export default function SearchBar(props) {
  const [value, setValue] = React.useState(null);
  const [APIData, setAPIData] = useState([]);
  const [index, setUniIndex] = useState();

  //uni index API call

  const leedsUniIndex = [
    {
      name: 'Leeds Beckett University - City Campus', 
      uniIndex: 35,
    },
    {
      name: 'Leeds Beckett University - Headingley',
      uniIndex: 36,
    },
    {
      name: 'Leeds Trinity University',
      uniIndex: 37,
    },
    {
      name: 'Leeds Arts University',
      uniIndex: 38,
    },
    {
      name: 'University of Leeds',
      uniIndex: 118,
    }]

  const handleSearch = () => {
    if (!value) return false

    let leedsUniIndexFind = leedsUniIndex.filter((uni) => {
      if (uni.name === value.name) {
        console.log('uni.uniIndex', uni.uniIndex)
        return uni.uniIndex
      }
    })
    // let index = APIData.findIndex(u => u.name === value.name)
    if (index !== -1) {
      // setUniIndex(index)
      // getData(index)
      // props.setUniAllIndex(index);
      setUniIndex(leedsUniIndexFind[0].uniIndex)
      getData(leedsUniIndexFind[0].uniIndex)
      props.setUniAllIndex(leedsUniIndexFind[0].uniIndex);
    }
  }



  const getData = (index) => {
    console.log('index', index)
    WebServiceClient.GetAll('listing/search?uniIndex=' + index + '&limit=70&skip=1').then(function (response) {
      if (response.data && response.data.data) {
        props.setProperties(response.data.data)
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    const fetchData = () => {
      WebServiceClient.FetchAll('swipad/unis')
        .then(function (response) {
          console.log('response', response)
          //delete leedsonly code when all cities are available
          let leedsOnly = response.data.data.filter((data) => {
            if (data.name.includes('Leeds')) { return data }
          })
          //uncomment setAPIData when all cities are avialalble 
          // setAPIData(response.data.data)

          //delete leedsonly code when all cities are available
          setAPIData(leedsOnly)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData()
  }, [])

  useEffect(() => {
    // console.log('value', value)
    handleSearch()
  }, [value])

  return (
    <Box sx={style.searchBar}>
      <Grid container spacing={2} sx={style.filterStack}>


        {/* hardcoded university of leeds */}
        {/* <Button onClick={(props.getData)} */}

        {/* //fetch all university data  */}
        <Grid item xs={12} sm={12} md={6} lg={6} >

          <Button onClick={() => handleSearch()}
            // onChange={event=>props.onChange(event.target.value)}
            sx={{ minWidth: "200px", width: "100%" }}
            style={style.searchButton}>
            Search For Your Perfect Student Home
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={5} >

          <Autocomplete
            sx={{ minWidth: "200px", width: "100%" }}
            id="header-search"
            freeSolo
            disableClearable
            onChange={(e, newValue) => setValue(newValue)}
            value={value}
            blurOnSelect
            options={APIData}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="Enter Location"
                InputProps={{
                  ...params.InputProps,
                  type: "search", endAdornment: (
                    <CssInputAdornment position="end">
                      <SearchIcon />
                    </CssInputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid>


      </Grid >
    </Box >
  )
}
