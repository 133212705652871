const axios = require('axios');

export class WebServiceClient {
  // static baseUrl = 'https://stage-api-swipad.ontheappt.cloud/api/';
  static baseUrl = 'https://prod-api-swipad.ontheappt.cloud/api/';

  //   'https://stage-api-swipad.ontheappt.cloud/api/listing/search?uniIndex=38&limit=10&skip=10';

  static GetAll(URL) {
    const params = '';
    return axios.get(WebServiceClient.baseUrl + URL + params);
  }

  static FetchAll(URL){
    const params = '';
    return axios.get(WebServiceClient.baseUrl + URL + params);

  }

}
